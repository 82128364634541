<template>
  <div class="w100p">
    <div class="flex-a-b-c h70 pr20 mb20">
      <div></div>
      <div class="d-flex">
        <el-button size="small" type="primary" @click="initialize_tap">
          恢复默认
        </el-button>
        <el-button size="small" type="primary" @click="save_template">
          保存
        </el-button>
      </div>
    </div>
    <div class="w100p d-flex">

      <!-- left -->
      <div class="w50p flex-c-c pt30 pb30" style="background:#F8F8F8;">
        <div class="phoneClass borderboxd-flex flex-column">
          <div class="d-flex oyauto imgScroll" style="height:650px;width:100%;">
            <div class="felx1 text-align">
              <p class="fs10 " style="line-height: 40px;">商城</p>
              <!-- 商城模板 -->
              <div class="d-flex flex-column">
                <div class="" v-for="(moduleList, index) in ecommerceList" :key="index">
                  <!-- 轮播图 -->
                  <div class="borderbox mb20 posre" v-if="moduleList.type == 'banners'"
                    @click="selected_tap(moduleList.type)">
                    <el-carousel trigger="click" height="272px">
                      <el-carousel-item v-for="(i, n) in moduleList.list" :key="n">
                        <img class="w100p h-272" :src="i.img" alt="">
                      </el-carousel-item>
                    </el-carousel>
                    <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out'">
                      <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                      <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                      <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>
                    </div>
                  </div>

                  <!-- 搜索 -->
                  <div class="borderbox pl5 pr5 mb20 posre" v-if="moduleList.type == 'search'"
                    @click="selected_tap(moduleList.type)">
                    <img :src="moduleList.img" class="w100p">
                    <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out'">
                      <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                      <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                      <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>
                    </div>
                  </div>

                  <!-- 分类 -->
                  <div class="borderbox pl5 pr5 mb-8 posre" v-if="moduleList.type == 'classify'"
                    @click="selected_tap(moduleList.type)">
                    <div class="w100p flex ju-be">
                      <div class="w-100 h-100 ra-2 mr-10" style="background: #D9D9D9;"></div>
                      <div class="w-100 h-100 ra-2 mr-10" style="background: #D9D9D9;"></div>
                      <div class="w-100 h-100 ra-2" style="background: #D9D9D9;"></div>
                    </div>
                    <div class="mt-18 size-14 text-left pb-18" style="border-bottom:1px solid #D9D9D9;">开启你的高级私人定制 </div>
                    <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out'">
                      <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                      <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                      <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>
                    </div>
                  </div>

                  <!-- 3D体验 -->
                  <div class="borderbox pl5 pr5 mb20 posre" v-if="moduleList.type == 'threeD'"
                    @click="selected_tap(moduleList.type)">
                    <div class="w100p flex ju-be size-14">
                      <div class="flex">
                        <div class="ptb-2 plr-4 co-ff" style="background:#3258A5;">3D体验</div>
                        <div style="color:#747474;">720°任意搭配</div>
                      </div>
                      <div class="" style="color:#747474;">已进行40.1万次定制</div>
                    </div>
                    <div class="mtb-8 w100p h-200 ra-2" style="background: #D9D9D9;"></div>
                    <div class="w100p flex ju-be">
                      <div class="w-100 h-100 ra-2 mr-10" style="background: #D9D9D9;"></div>
                      <div class="w-100 h-100 ra-2 mr-10" style="background: #D9D9D9;"></div>
                      <div class="w-100 h-100 ra-2" style="background: #D9D9D9;"></div>
                    </div>
                    <div class="mt-18" style="border-bottom:1px solid #D9D9D9;"> </div>
                    <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out'">
                      <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                      <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                      <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>
                    </div>
                  </div>

                  <!-- 商品 -->
                  <div class="borderbox pl5 pr5 mb20 posre" v-if="moduleList.type == 'product'"
                    @click="selected_tap(moduleList.type)">
                    <div class="flex">
                      <div class="w-4 h-13" style="background:#3258A5;"></div>
                      <div class="fw-5 ml-4">定制商品</div>
                    </div>
                    <div class="w100p bg-ff">
                      <div class="ra-8 ov-hd mt-18">
                        <div class="w100p h-264" style="background:#D9D9D9;">
                        </div>
                        <div class="plr-10 ptb-14 text-left">
                          【三维建模】三维交互模型
                        </div>
                      </div>
                      <div class="ra-8 ov-hd mt-18">
                        <div class="w100p h-264" style="background:#D9D9D9;">
                        </div>
                        <div class="plr-10 ptb-14 text-left">
                          【三维建模】三维交互模型
                        </div>
                      </div>
                      <div class="ra-8 ov-hd mt-18">
                        <div class="w100p h-264" style="background:#D9D9D9;">
                        </div>
                        <div class="plr-10 ptb-14 text-left">
                          【三维建模】三维交互模型
                        </div>
                      </div>
                    </div>
                    <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out'">
                      <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                      <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                      <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="d-flex flex1 bgfff justify-content-between">
            <div style="width:52px;height:100%" class="flex-c-c flex-column" v-for="(item, index) in tabList"
              :key="index">
              <img :src="index == 2 ? item.selectedIconPath : item.iconPath" class="w40 h40">
              <p class="fs6">{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- right -->
      <div class="w50p posre d-flex oyauto oscrollbar">
        <div class="flex1 h550 pt20 pl20 pb20 w100p">
          <div class="mt20" v-for="(moduleList, index) in ecommerceList" :key="index">

            <!-- tab -->
            <div class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500"
              v-if="currentType == 'tab' && moduleList.type == currentType">
              <tab :listData="moduleList" @confirmSeckill="confirmSeckill" @reset_tab_tap="reset_tab_tap">

              </tab>

            </div>
            <!-- diy1 -->
            <div class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500"
              v-else-if="currentType == 'diy1' && moduleList.type == currentType">
              <p class="textc fs9 mb20 fwbold">推荐模块</p>
              <div class="mb20 posre " v-for="(item, k) in moduleList.list" :key="k">
                <div class="" v-if="k == 0 || k == 2">
                  <p class="fs8 fwbold mb10">{{ k == 0 ? '左侧模块' : '右侧模块' }}</p>
                  <p class="fs8 mb10">模块名称</p>
                  <el-input :placeholder="item.title" v-model="item.title" maxlength="4" show-word-limit
                    class="mb10 w200 "></el-input>
                </div>
                <p class="fs8 mb10">图片{{ k + 1 }}</p>
                <div class="bgf7fa pa10 d-flex align-items-center">

                  <div class="d-flex flex-column align-items-center ">
                    <el-upload accept="image/jpeg,image/png" class="addTabIcon" :action="uploadUrl"
                      :show-file-list="false"
                      :on-success="bannerPicSuccess.bind(null, { 'type': currentType, 'index': k, 'data': item })"
                      :before-upload="beforeUploadImageDynamicPic">
                      <img v-if="item.img" :src="item.img" class="avatar dynamic ">
                      <i v-else class="el-icon-plus"></i>
                    </el-upload>
                    <p class="fs6">建议上传{{ item.size }}px的图片效果最佳</p>
                  </div>
                  <jumpSet title="123123123" :index="currentIndex" :currentSelect="k"
                    @currentSelect_tap="currentSelect_tap" :currentGoods="item" :goodsData="item"
                    @select_tap="select_tap"></jumpSet>
                </div>
                <div class="mt10" v-if="k !== 2">
                  <p class="fs8 fwbold mb10">文案设置</p>
                  <el-input :placeholder="item.text" v-model="item.text" maxlength="4" show-word-limit
                    class="mb10 w200 "></el-input>
                </div>
              </div>


            </div>
            <!-- 热销爆款 -->
            <div class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500"
              v-else-if="currentType == 'boutique' && moduleList.type == currentType">
              <specialGoods :listData="moduleList" type="isOrdinary" @confirmSeckill="confirmSeckill">
              </specialGoods>
            </div>

            <!-- 精选推荐 -->
            <div class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500"
              v-else-if="currentType == 'boutique1' && moduleList.type == currentType">
              <specialGoods :listData="moduleList" type="isOrdinary" @confirmSeckill="confirmSeckill">
              </specialGoods>
            </div>

          </div>
        </div>



      </div>

    </div>
  </div>
</template>
  
<script>
import {
  saveShopModelConfig,
  queryShopModelConfig,
  updateShopModelConfig
} from "@/api/wechat";
import { getDataTimeSec } from "@/utils";
import jumpSet from "./components/jumpSet";
import specialGoods from './numericalCode/specialGoods'
import tab from './numericalCode/tab'


export default {
  data() {
    return {
      uploadUrl: this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      isUpdate: false,
      tabList: [
        {
          text: "名片",
          pagePath: "pages/index/main",
          iconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/card.png",
          selectedIconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/card_select.png"
        },
        {
          text: "媒体",
          pagePath: "pages/media/main",
          iconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/video.png",
          selectedIconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/video_select.png"
        },
        {
          text: "商城",
          pagePath: "pages/Products/main",
          iconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/prod_gray.png",
          selectedIconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/prod_select.png"
        },
        {
          text: "动态",
          pagePath: "pages/Dynamic/main",
          iconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/browser.png",
          selectedIconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/browser_select.png"
        },
        {
          text: "官网",
          pagePath: "pages/WebSite/main",
          iconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/computer.png",
          selectedIconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/computer_select.png"
        },
      ],
      ecommerceList: [{
        type: 'banners',
        list: [{
          img: 'http://cdn-oss.jquen.com/product-index/20190418160834.png'
        }
        ]
      }, {
        img: require('../../../../src/static/icon_search.png'),
        type: 'search'
      },{
        type:'classify',
        list:[]
      },{
        type:'three_D',
        list:[]
      },{
        type:'product',
        list:[]
      }],
      currentType: '',
      currentIndex: 0,
      currentSelect: '',
      initializeData: "",
      requestWay: 'save'
    };
  },
  components: {
    jumpSet,
    specialGoods,
    tab
  },
  created() {
    this.initializeData = JSON.parse(JSON.stringify(this.ecommerceList))
    console.log(this.initializeData, '999999999*******')
    this.queryShopConfig()
  },
  mounted() {

  },
  methods: {
    queryShopConfig() {
      let data = {
        modelEnum: '3DGaoDing',
      }
      queryShopModelConfig(data)
        .then(res => {
          console.log(res)
          if (res.data.modelId) {
            this.requestWay = 'up'
            this.ecommerceList = JSON.parse(res.data.shopDetails[0].content);
            console.log(this.ecommerceList, '请求数据');
          }
        })
    },
    selected_tap(type) {
      let index = ''
      this.ecommerceList.forEach((item, k) => {
        if (item.type == type) {
          this.currentIndex = k
        }
      })
      console.log(this.currentIndex)
      this.currentType = type
    },
    bannerPicSuccess(obj, res, file) {
      //动态图上传成功
      let than = this
      console.log(obj)
      this.ecommerceList.forEach((item, k) => {
        if (item.type == obj.type) {

          than.$set(than.ecommerceList[k].list[obj.index], 'img', this.ossUrl + '/' + res.data)
        }
      })
    },

    // 上移
    templateUp() {
      let arr = this.ecommerceList,
        index = this.currentIndex
      if (index !== 0) {
        this.swapArray(arr, this.currentIndex, --this.currentIndex)
      } else {
        this.$message.error('已经处于置顶，无法上移')
      }
    },
    templateDown() {
      let arr = this.ecommerceList,
        length = this.ecommerceList.length,
        index = this.currentIndex
      if (index + 1 != length) {
        this.swapArray(arr, this.currentIndex, ++this.currentIndex)
      } else {
        this.$message.error('已经触底，无法下移')
      }
    },
    templateDelete() {
      let index = this.currentIndex
      this.ecommerceList.splice(index, 1)
    },
    swapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    beforeUploadImageDynamicPic(file) {
      var _this = this;
      var isLt10M = file.size / 1024 / 1024 < 10;
      if (['image/jpeg', 'image/png'].indexOf(file.type) == -1) {
        _this.$message.error('请上传正确的图片格式');
        return false;
      }
      if (!isLt10M) {
        _this.$message.error('上传图片大小不能超过10MB哦!');
        return false;
      }
    },
    currentSelect_tap(index) {
      this.currentSelect = index
    },
    select_tap(index, val, type, goods, currentSelect) {
      if (goods) {
        if (currentSelect || currentSelect === 0) {
          this.ecommerceList[this.currentIndex].list[currentSelect].goods = goods
          delete this.ecommerceList[this.currentIndex].list[currentSelect].page
        } else {
          this.ecommerceList[this.currentIndex].list[this.currentSelect].goods = goods
          delete this.ecommerceList[this.currentIndex].list[this.currentSelect].page
        } page
      } else {
        if (type) {
          if (currentSelect || currentSelect === 0) {
            this.ecommerceList[this.currentIndex].list[currentSelect].page = type
            delete this.ecommerceList[this.currentIndex].list[currentSelect].goods
          } else {
            this.ecommerceList[this.currentIndex].list[this.currentSelect].page = type
            delete this.ecommerceList[this.currentIndex].list[this.currentSelect].goods
          }
        }

      }
    },
    //重置数据
    initialize_tap() {
      this.ecommerceList = JSON.parse(JSON.stringify(this.initializeData))
    },
    //保存模板
    save_template() {
      let than = this
      let arr = JSON.parse(JSON.stringify(this.ecommerceList))
      try {

      }
      catch (e) {
        if (e) {
          return than.$message.error('商城数据未完整');
        }
      }
      let p = new Promise((resolve, reject) => {
        arr.forEach((item, k) => {
          if (item.list) {
            item.list.forEach((v, k) => {
              if (item.type == 'boutique' || item.type == 'boutique1') {
                if (!v.goodsId) {
                  reject("精选模块未设置商品")
                }
              }
            })
          }
        })
        resolve('succ')
      })
      p.then(res => {
        console.log(res, '22222');
        let data = {
          modelEnum: '3DGaoDing',
          shopDetails: [{
            content: JSON.stringify(arr)
          }]
        }
        if (than.requestWay == 'save') {
          saveShopModelConfig(data)
            .then(res => {
              if (res.code == 200) {
                than.$message.success("保存成功")
                setTimeout(() => {
                  than.queryShopConfig()
                  than.$router.push('mallSetting')
                }, 500)
              }
            })
            .catch(err => {
              if (err.code == 201) {
                this.$message.error(err.message)
              }
              console.log(err, 'wwwww');
            })
        } else {
          updateShopModelConfig(data)
            .then(res => {
              if (res.code == 200) {
                than.$message.success("保存成功")
                setTimeout(() => {
                  than.queryShopConfig()
                  than.$router.push('mallSetting')
                }, 500)
              }
            })
            .catch(err => {
              if (err.code == 201) {
                this.$message.error(err.message)
              }
              console.log(err, 'wwwww');
            })
        }
      })
        .catch(err => {
          this.$message.error(err)
        })
    },
    //重置tab
    reset_tab_tap(data) {
      let than = this
      than.ecommerceList.forEach((item, k) => {
        if (item.type == 'tab') {
          than.$set(than.ecommerceList, k, data)
        }
      })
    },
    //确认秒杀商品
    confirmSeckill(index, data, type) {
      console.log(index, data, type)
      let than = this
      than.ecommerceList.forEach((item, k) => {
        if (item.type == type) {
          than.ecommerceList[k].list[index] = data
          than.$set(than.ecommerceList[k].list, index, data)
        }
      })

    }
  }
};
</script>
  
<style lang="scss" scoped>
div {
  box-sizing: border-box;
}

.phoneClass {
  padding: 50px 30px 0px 30px;
  background-image: url('../../../assets/images/iphonex.png');
  width: 400px;
  height: 800px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.imgScroll::-webkit-scrollbar {
  width: 0 !important
}

.group_title {
  background-image: url('../../../static/ecommerce/bg1.png');
  background-repeat: no-repeat;
  background-size: 100%;
}

.group_bgcolor {
  background: linear-gradient(0deg, rgba(253, 78, 86, 0.3), #FD4E56);
}

.purchase {
  width: 74px;
  height: 32px;
  background: #FFA133;
  color: #fff;
  border-radius: 16px 0px 0px 16px;
  right: 0px;
  top: 45px;
  display: flex;
  font-weight: bold;
  align-items: center;
  padding-left: 11px;
  font-size: 16px;
}

.choicenessBgImg {
  bottom: 0px;
}

.choicenessBox {
  padding: 18px;
  width: 100p;
  background: #FFFFFF;
  box-shadow: 0px 2px 7px 0px rgba(56, 56, 56, 0.1);
  border-radius: 9px;
  display: flex;
}

.fade_in {
  opacity: 1;
  z-index: 99;
  transition: all 0.2s;
}

.fade_out {
  opacity: 0;
  display: none !important;
  transition: all 0.2s;
}

.control {
  right: 0px;
  top: 0px;
  width: 25px;
  height: 150px;
  background: $color-1;
  z-index: 99;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  font-size: 18px;
}

/deep/.addTabIcon .el-upload--text {
  width: 210px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 10px;
}

/deep/ .addTabIcon .el-upload-list__item {
  width: 210px;
  height: 100px;
  line-height: 100px;
}

.avatar {
  width: 100%;
  height: 100%;
}

.recommend {
  padding: 9px;
  background: #FFFFFF;
  box-shadow: 0px 2px 7px 0px rgba(56, 56, 56, 0.1);
  border-radius: 9px;
  margin-bottom: 20px;
}

.diy1Bottom {
  position: absolute;
  width: 88%;
  height: 18px;
  line-height: 18px;
  color: #fff;
  font-size: 12px;
  background: linear-gradient(0deg, #000000, rgba(0, 0, 0, 0));
  border-radius: 0px 0px 5px 5px;
  bottom: 4px;
}
</style>