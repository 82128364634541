<template>
  <div class="appointmentList-view">
    <numericalCode></numericalCode>
  </div>
</template>

<script>
import {
  saveTabMenu,
  queryTabMenu
} from "@/api/wechat";
import { getDataTimeSec } from "@/utils";
import numericalCode from "./template/threeGaoDing"

export default {
  name: "appointmentList",
  data() {
    
    return {
      type:""
    };
  },
  components:{
    numericalCode
  },
  mounted(){
  },
  methods:{
    
  }
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  display: flex;
  background: #ffffff;
}
.phoneClass{
  padding:50px 30px 0px 30px;
  background-image:url('../../assets/images/iphonex.png') ;
  width:400px;
  height:800px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.phoneClass img{
  width: 100%;
}
.imgScroll::-webkit-scrollbar { width: 0 !important }
</style>